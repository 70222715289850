import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from './events/Store'

import Http from './events/Http'
import Config from './events/Config'

import Common from './common'

import Login from './components/Login'
import Home from './components/Home'

/** Users **/
import UsersItems from './components/users/Items'
import UsersItem from './components/users/Item'
import UsersLogs from './components/users/Logs'
import UsersKyc from './components/users/Kyc'

/** Users Status **/
import UsersStatusItems from './components/users/status/Items'
import UsersStatusItem from './components/users/status/Item'


/** Tournaments **/
import TournamentsItems from './components/tournaments/Items'
import TournamentsItem from './components/tournaments/Item'
import TournamentsCreate from './components/tournaments/Create'

/** Experience **/
import ExperienceItems from './components/experience/Items'
import ExperienceItem from './components/experience/Item'
import ExperienceCreate from './components/experience/Create'
import ExperienceProductsItems from './components/experience/products/Items'
import ExperienceProductsItem from './components/experience/products/Item'
import ExperienceProductsCreate from './components/experience/products/Create'
import ExperienceProductsCategories from './components/experience/products/Categories'
import ExperienceProductsHistory from './components/experience/products/History'

/** Promotions **/
import PromotionsCreate from './components/promotions/Create'
import PromotionsItems from './components/promotions/Items'
import PromotionsItem from './components/promotions/Item'
import PromotionsTemplatesCreate from './components/promotions/templates/Create'
import PromotionsTemplatesItems from './components/promotions/templates/Items'
import PromotionsTemplatesItem from './components/promotions/templates/Item'

/** Promocodes **/
import PromocodesCreate from './components/promocodes/Create'
import PromocodesItems from './components/promocodes/Items'
import PromocodesItem from './components/promocodes/Item'

/** Games **/
import GamesItems from './components/games/Items'
import GamesItem from './components/games/Item'
import GamesLogs from './components/games/Logs'
import GamesAnalytics from './components/games/Analytics'
import GamesCategories from './components/games/Categories'
import GamesProviders from './components/games/Providers'

/** Cashier **/
import PaymentsItems from './components/payments/Items'
import PaymentsSecondItems from './components/payments/SecondItems'
import PaymentsAnalytics from './components/payments/Analytics'

/** Merchants **/
import MerchantsItems from './components/merchants/Items'
import MerchantsItem from './components/merchants/Item'
import MerchantsCreate from './components/merchants/Create'

/** Paysys **/
import PaysystemsItems from './components/paysystems/Items'
import PaysystemsItem from './components/paysystems/Item'
import PaysystemsCreate from './components/paysystems/Create'

/** Messages **/
import MessagesItems from './components/messages/Items'
import MessagesItem from './components/messages/Item'
import MessagesCreate from './components/messages/Create'

/** Banners **/
import BannersItems from './components/banners/Items'
import BannersItem from './components/banners/Item'
import BannersCreate from './components/banners/Create'

/** Email **/
import EmailCreate from './components/email/Create'
import EmailItem from './components/email/Item'
import EmailHistory from './components/email/History'

/** Settings **/
import SettingsMirrors from './components/settings/Mirrors'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: '',
    linkExactActiveClass: '',
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                auth: 'guest'
            }
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users',
            name: 'UsersItems',
            component: UsersItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users/edit/:id',
            name: 'UsersItem',
            component: UsersItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users/logs',
            name: 'UsersLogs',
            component: UsersLogs,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users/kyc',
            name: 'UsersKyc',
            component: UsersKyc,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users/status',
            name: 'UsersStatusItems',
            component: UsersStatusItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/users/status/edit/:id',
            name: 'UsersStatusItem',
            component: UsersStatusItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/tournaments/create',
            name: 'TournamentsCreate',
            component: TournamentsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/tournaments/edit/:id',
            name: 'TournamentsItem',
            component: TournamentsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/tournaments/:status',
            name: 'TournamentsItems',
            component: TournamentsItems,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/create',
            name: 'ExperienceCreate',
            component: ExperienceCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/edit/:id',
            name: 'ExperienceItem',
            component: ExperienceItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience',
            name: 'ExperienceItems',
            component: ExperienceItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/products/create',
            name: 'ExperienceProductsCreate',
            component: ExperienceProductsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/products/edit/:id',
            name: 'ExperienceProductsItem',
            component: ExperienceProductsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/products/items',
            name: 'ExperienceProductsItems',
            component: ExperienceProductsItems,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/products/categories',
            name: 'ExperienceProductsCategories',
            component: ExperienceProductsCategories,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/experience/products/history',
            name: 'ExperienceProductsHistory',
            component: ExperienceProductsHistory,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions/templates',
            name: 'PromotionsTemplatesItems',
            component: PromotionsTemplatesItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions/templates/create',
            name: 'PromotionsTemplatesCreate',
            component: PromotionsTemplatesCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions/templates/edit/:id',
            name: 'PromotionsTemplatesItem',
            component: PromotionsTemplatesItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions',
            name: 'PromotionsItems',
            component: PromotionsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions/create',
            name: 'PromotionsCreate',
            component: PromotionsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promotions/edit/:id',
            name: 'PromotionsItem',
            component: PromotionsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promocodes',
            name: 'PromocodesItems',
            component: PromocodesItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promocodes/create',
            name: 'PromocodesCreate',
            component: PromocodesCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/promocodes/edit/:id',
            name: 'PromocodesItem',
            component: PromocodesItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games',
            name: 'GamesItems',
            component: GamesItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games/edit/:id',
            name: 'GamesItem',
            component: GamesItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games/logs',
            name: 'GamesLogs',
            component: GamesLogs,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games/analytics',
            name: 'GamesAnalytics',
            component: GamesAnalytics,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games/categories',
            name: 'GamesCategories',
            component: GamesCategories,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/games/providers',
            name: 'GamesProviders',
            component: GamesProviders,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/payments/items/:type',
            name: 'PaymentsItems',
            component: PaymentsItems,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/payments/second/items/:type',
            name: 'PaymentsSecondItems',
            component: PaymentsSecondItems,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/payments/analytics',
            name: 'PaymentsAnalytics',
            component: PaymentsAnalytics,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/merchants',
            name: 'MerchantsItems',
            component: MerchantsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/merchants/create',
            name: 'MerchantsCreate',
            component: MerchantsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/merchants/edit/:id',
            name: 'MerchantsItem',
            component: MerchantsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/paysystems',
            name: 'PaysystemsItems',
            component: PaysystemsItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/paysystems/create',
            name: 'PaysystemsCreate',
            component: PaysystemsCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/paysystems/edit/:id',
            name: 'PaysystemsItem',
            component: PaysystemsItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/messages/create',
            name: 'MessagesCreate',
            component: MessagesCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/messages/items/:type',
            name: 'MessagesItems',
            component: MessagesItems,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/messages/item/:id',
            name: 'MessagesItem',
            component: MessagesItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/banners',
            name: 'BannersItems',
            component: BannersItems,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/banners/create',
            name: 'BannersCreate',
            component: BannersCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/banners/edit/:id',
            name: 'BannersItem',
            component: BannersItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/email/create',
            name: 'EmailCreate',
            component: EmailCreate,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/email/edit/:id',
            name: 'EmailItem',
            component: EmailItem,
            props: true,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/email/history',
            name: 'EmailHistory',
            component: EmailHistory,
            meta: {
                auth: 'auth'
            }
        },
        {
            path: '/settings/mirrors',
            name: 'SettingsMirrors',
            component: SettingsMirrors,
            meta: {
                auth: 'auth'
            }
        }
    ]
})

router.beforeEach(async (to, from, next) => {

    var user = Store.state.user

    if(!('auth' in user)) {
        await Http.post(`${Config.ApplicationAPI}/auth/check`)
        .then((response) => {
            response = response.data
            switch (response.status) {
                case 'error':
                    console.log('check auth error')
                    break
                case 'success':
                    document.body.classList.remove('overflow-hidden')
                    Store.commit('setLoaded', true)
                    Store.commit('setUser', response.user)
                    break
                default:
                    console.log('Unknown error')
            }
        }).catch((error) => {
            document.body.classList.remove('overflow-hidden')
            Store.commit('setLoaded', true)
        })

        user = Store.state.user
    }

    if((!('auth' in user) || !user.auth) && to.meta.auth == 'auth' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Login'})
    } else if((('auth' in user) && user.auth) && to.meta.auth == 'guest' && from.meta.auth != to.meta.auth) {
        router.push({name: 'Home'})
    }

    next()

})


export default router
<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Статус {{data.name}}</h2>
                        </div>
                    </div>

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Код валюты"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.cashback"
                                            @input="errors.cashback = null"
                                            :class="{'is-invalid': errors.cashback}"
                                            placeholder="Код валюты"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Кэшбэк (%)</label>
                                        <div class="invalid-feedback">{{errors.cashback}}</div>
                                    </div>
                                </div>

                                <h4 class="fs-1 mt-5">Сумма ставок</h4>

                                <template v-for="(item, key) in data.data">
                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating v-select-medium" :class="{'v-select-error': errors.data[key].merchant}">
                                            <vue-select
                                                disabled
                                                v-model="data.data[key].merchant"
                                                label="name">
                                                <template v-slot:no-options="{ search, searching }">
                                                  <template v-if="searching">
                                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                                  </template>
                                                </template>
                                            </vue-select>
                                            <label>Мерчант</label>
                                            <div class="invalid-feedback">{{errors.data[key].merchant}}</div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.data[key].bets"
                                                @input="errors.data[key].bets = null"
                                                :class="{'is-invalid': errors.data[key].bets}"
                                                placeholder="Сумма ставок"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Сумма ставок</label>
                                            <div class="invalid-feedback">{{errors.data[key].bets}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-4">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.data[key].max_withdrawal"
                                                @input="errors.data[key].max_withdrawal = null"
                                                :class="{'is-invalid': errors.data[key].max_withdrawal}"
                                                placeholder="Макс. вывод в сутки"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Макс. вывод в сутки</label>
                                            <div class="invalid-feedback">{{errors.data[key].max_withdrawal}}</div>
                                        </div>
                                    </div>
                                </template>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                id: null,
                name: null,
                cashback: null,
                data: []
            },
            errors: {
                name: false,
                cashback: false,
                data: []
            },
            utils: {
                merchants: [],
                data: {}
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/users/status/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data = response.data
                        this.utils.data = this.data.data
                        this.data.data = []

                        for (var item of this.utils.merchants) {
                            this.data.data.push({
                                merchant: {
                                    name: item.name,
                                    key: item.id
                                },
                                bets: this.utils.data && item.id in this.utils.data ? this.utils.data[item.id].bets : null,
                                max_withdrawal: this.utils.data && item.id in this.utils.data ? this.utils.data[item.id].max_withdrawal : null,
                            })
                            this.errors.data.push({
                                merchant: null,
                                bets: null,
                                max_withdrawal: null,
                            })
                        }
                        
                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'UsersStatusItems'})
                }
            })
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.cashback) {
                this.errors.cashback = 'Это поле обязательно'
                return
            }

            for (var i = 0; i < this.data.data.length; i++) {
                if (!this.data.data[i].merchant) {
                    this.errors.data[i].merchant = 'Это поле обязательно'
                    return
                }

                if (!this.isNumeric(this.data.data[i].bets)) {
                    this.errors.data[i].bets = 'Это поле обязательно'
                    return
                }

                if (!this.data.data[i].max_withdrawal) {
                    this.errors.data[i].max_withdrawal = 'Это поле обязательно'
                    return
                }

            }
            
            this.btn_preloader = true
            this.$axios.post(`/users/status/update`, JSON.stringify(this.data))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getMerchants () {
            await this.$axios.post(`/merchants/items`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchants = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'UsersStatusItems'})
                }
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        isNumeric (num) {
            return !isNaN(num)
        }
    },
    async beforeMount() {
        await this.getMerchants()
        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear, ::v-deep .vs__actions .fa-angle-down {
        display: none;
    }
</style>
